import { OrderStepEnum, type Order } from "@/types/order";

export default defineNuxtRouteMiddleware(async (to, from) => {
  const transferMap = [
    OrderStepEnum.TRANSFERS,
    OrderStepEnum.EXTRAS,
    OrderStepEnum.EXTRA_PROGRAMS,
    OrderStepEnum.ADDITIONS,
  ];
  const confirmationMap = [OrderStepEnum.CONFIRMATION, OrderStepEnum.AUDIT];

  const booking = useBooking();

  const current = useCurrentStep(to.fullPath);
  let bookingCurrentStep = booking.value.current_step;

  const findStep = (orderStep: OrderStepEnum) =>
    booking.value.order_steps.find(
      (item) =>
        item.order_step === orderStep ||
        (item.order_step === OrderStepEnum.ADDITIONS &&
          orderStep === OrderStepEnum.TRANSFERS) ||
        (item.order_step === OrderStepEnum.PERSONAL_DATA &&
          orderStep === OrderStepEnum.TRAVELERS)
    );

  const isStepInMap = (step: OrderStepEnum, map: Array<OrderStepEnum>) =>
    map.includes(step);

  if (
    current === OrderStepEnum.TRANSFERS &&
    isStepInMap(bookingCurrentStep, transferMap)
  ) {
    bookingCurrentStep = OrderStepEnum.TRANSFERS;
  } else if (
    current === OrderStepEnum.CONFIRMATION &&
    isStepInMap(bookingCurrentStep, confirmationMap)
  ) {
    bookingCurrentStep = OrderStepEnum.CONFIRMATION;
  }

  if (
    current &&
    booking.value.order_steps.length !== 0 &&
    current !== bookingCurrentStep
  ) {
    const step = findStep(
      current === OrderStepEnum.PAYMENT
        ? OrderStepEnum.PERSONAL_DATA
        : (current as OrderStepEnum)
    );

    // user goes forward
    if (!step?.checked && to.fullPath !== from.fullPath) {
      history.back();
    }

    // user goes backward
    // reset step
    if (useAuth().valid.value) {
      const { data } = await useGo7seasApi<Order>(`/orders/steps/${current}`, {
        method: "PATCH",
      });

      if (data.value) {
        booking.value = data.value;
      }
    }
    location.reload();
  }
});
